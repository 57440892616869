module.exports = [{
      plugin: require('/Users/parkerhenderson/Code/personal-blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/parkerhenderson/Code/personal-blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/parkerhenderson/Code/personal-blog/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/parkerhenderson/Code/personal-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
